import { useAtom, useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { ListItem } from "./ListItem.jsx";

export const List = () => {
    const { name } = useAtomValue(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [, setSelectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const [, setShowDetailsView] = useAtom(atoms.showDetailsView);

    return (
        <div className="py-2">
            {itemsAtoms.map((itemAtom, i) => (
                <ListItem
                    key={name + i}
                    atom={itemAtom}
                    onClick={() => {
                        setSelectedItemIndex(i);
                        setShowDetailsView(true);
                    }}
                />
            ))}
        </div>
    );
};
List.displayName = "list";
