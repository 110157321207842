"use client";

import { json } from "@codemirror/lang-json";
import { emptyApp, formatTime, Model } from "@glide/appgpt-common";
import ReactCodeMirror from "@uiw/react-codemirror";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";

import * as atoms from "@/atoms.js";
import { App } from "@/components/App/index.jsx";

const Header: React.FC<{ children: React.ReactNode }> = props => (
    <div {...props} className="text-2xl font-semibold grow">
        {props.children}
    </div>
);

const Button: React.FC<{ disabled?: boolean } & React.HTMLAttributes<HTMLButtonElement>> = props => (
    <button
        {...props}
        className="bg-gray-200 hover:bg-gray-300 px-3 py-2 rounded-lg text-sm disabled:opacity-50 disabled:hover:bg-gray-200"
    >
        {props.children}
    </button>
);

export const Editor: React.FC = () => {
    const [busy, setBusy] = useState(false);
    const [elapsed, setElapsed] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [app, setApp] = useAtom(atoms.app);

    const [prompt, setPrompt] = useAtom(atoms.prompt);
    const [activePrompt, setActivePrompt] = useState(prompt);

    const [model, setModel] = useAtom(atoms.model);
    const [streaming, setStreaming] = useAtom(atoms.streaming);

    const generate = useCallback(async () => {
        if (prompt.trim().length === 0) return;
        setApp(emptyApp);
        setStartTime(performance.now());
        setBusy(true);
        setActivePrompt(prompt);
    }, [prompt, setApp]);

    const finishedGenerating = useCallback(async () => {
        setBusy(false);
    }, []);

    useEffect(() => {
        let interval: any;
        if (busy) {
            interval = setInterval(() => {
                setElapsed(performance.now() - startTime);
            }, 100);
            return () => clearInterval(interval);
        } else {
            clearInterval(interval);
        }
    }, [busy, startTime]);

    return (
        <main className="grid grid-cols-2 h-screen">
            <div className="p-5 flex flex-col space-y-4 h-full overflow-scroll">
                <div className="flex">
                    <Header>Editor</Header>
                    <div className="space-x-3">
                        <Button onClick={() => setStreaming(!streaming)}>{streaming ? "Stream" : "Strict"}</Button>
                        <Button onClick={() => setModel(model === Model.GPT3 ? Model.GPT4o : Model.GPT3)}>
                            {model}
                        </Button>
                        <Button
                            onClick={generate}
                            disabled={busy || prompt.trim().length === 0 || prompt.trim() === activePrompt.trim()}
                        >
                            Generate app
                        </Button>
                    </div>
                </div>
                <textarea
                    autoFocus
                    value={prompt}
                    className="w-full h-36 shrink-0 p-4 bg-gray-200 rounded resize-none"
                    placeholder="Describe your app"
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            generate();
                            return false;
                        }
                    }}
                    onChange={e => {
                        const prompt = e.target.value;
                        setPrompt(prompt);
                    }}
                ></textarea>
                <div className="border p-2 text-xs rounded overflow-y-scroll text-neutral-800 grow">
                    <ReactCodeMirror
                        value={JSON.stringify(app, null, 2)}
                        className="h-full text-sm"
                        basicSetup={{}}
                        extensions={[json()]}
                    />
                </div>
            </div>
            <div className="p-5 w-full">
                <div className="flex flex-col h-full">
                    <div className="flex flex-col">
                        <div className="mb-2 flex items-center">
                            <Header>App</Header>
                            <div className="text-xs">
                                {busy ? "Generating for" : "Generated in"} {formatTime(elapsed)} sec.
                            </div>
                        </div>
                    </div>
                    <App prompt={activePrompt} key={activePrompt} finishedGenerating={finishedGenerating} />
                </div>
            </div>
        </main>
    );
};
