"use client";

import { Schema } from "@effect/schema";
import { emptyApp, isStandalone, sleep } from "@glide/appgpt-common";
import * as solid from "@heroicons/react/24/solid";
import { Route, useNavigate, useRouter } from "@tanstack/react-router";
import classNames from "classnames";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useAtom, useAtomValue } from "jotai";
import React, { useCallback, useEffect, useState } from "react";

import * as atoms from "@/atoms.js";
import { App } from "@/components/App/index.jsx";
import { rootRoute } from "@/routes.js";

import { useAnalytics } from "../analytics/index.js";
import * as builder from "../builder.js";
import { useOnce } from "./hooks.js";
import { PexelsAttribution } from "./PexelsAttribution.js";
import { PrimaryButton } from "./PrimaryButton.jsx";
// import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { TabsEditor } from "./TabsEditor.jsx";

const boxVariant = {
    hidden: {
        scale: 0.95,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            duration: 0.3,
            when: "beforeChildren", //use this instead of delay
            staggerChildren: 0.25, //apply stagger on the parent tag
        },
    },
};

const itemVariants: Variants = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, ease: "easeOut" },
    },
    hidden: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const placeholderVariants = [
    "Task tracker to create and assign tasks for facility maintenance team",
    "Customer portal to track order status and shipping details",
    "Inventory management tool to check out and return construction equipment",
    "A knowledge management tool for field technicians to see product specs and installation guides",
];

const SearchParams = Schema.Struct({
    description: Schema.optional(Schema.String),
});

export const generatorPageRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/",
    // parseParams: Schema.parseSync(SearchParams),
    // stringifyParams: Schema.encodeSync(SearchParams),
    validateSearch: Schema.decodeSync(SearchParams),
    component: () => <GeneratorPage />,
});

export const GeneratorPage: React.FC = () => {
    const [busy, setBusy] = useState(false);
    const [app, setApp] = useAtom(atoms.app);
    const hasTabsMetadata = app.tabs.some(t => t.databaseColumns.length > 0);
    const problemRef = React.useRef<HTMLTextAreaElement>(null); // autofocus doesn't work in iframe

    const [prompt, setPrompt] = useState("");
    const [activePrompt, setActivePrompt] = useState(prompt);
    const searchParams = generatorPageRoute.useSearch();
    const router = useRouter();
    // const pathname = usePathname();
    const pathname = "";
    const { trackEvent, trackEventOnce } = useAnalytics();
    const showDetailsView = useAtomValue(atoms.showDetailsView);
    const [, setActiveTabName] = useAtom(atoms.activeTabName);

    const [placeholder, setPlaceholder] = useState("");

    const placeholderRef = React.useRef(placeholder);
    placeholderRef.current = placeholder;

    const [placeholderVariant, setPlaceholderVariant] = useState(0);
    const [placeholderWillFade, setPlaceholderWillFade] = useState(false);

    const placeholderVariantRef = React.useRef(placeholderVariant);
    placeholderVariantRef.current = placeholderVariant;

    const placeholderAnimating = prompt.length === 0;

    if ((window as any).throwError) {
        throw new Error("Test");
    }

    useOnce(async () => {
        trackEvent("start");

        await sleep(1000);

        while (true) {
            if (!placeholderAnimating) return;

            const placeholder = placeholderRef.current;
            const placeholderVariant = placeholderVariantRef.current;

            const variant = placeholderVariants[placeholderVariant];

            if (variant === placeholder) {
                await sleep(1500);
                setPlaceholderWillFade(true);
                await sleep(200);
                setPlaceholderWillFade(false);
                setPlaceholder("");
                setPlaceholderVariant((placeholderVariant + 1) % placeholderVariants.length);
                continue;
            }

            const variantWords = variant.split(" ");
            const nextWord = placeholder === "" ? variantWords[0] : variantWords[placeholder.split(" ").length];
            const newPlaceholder = [placeholder, nextWord].join(" ").trim();

            await sleep(nextWord.length * 25);
            setPlaceholder(newPlaceholder);
        }
    });

    const generate = useCallback(async () => {
        if (prompt.trim().length === 0) return;
        setApp(emptyApp);
        setActiveTabName(undefined);
        setBusy(true);
        setActivePrompt(prompt);
        trackEvent("Generate App");
        builder.notifyStartedGenerating();
    }, [prompt, setApp, trackEvent, setActiveTabName]);

    const finishedGenerating = useCallback(async () => {
        setBusy(false);
    }, []);

    const navigate = useNavigate({ from: generatorPageRoute.id });

    useEffect(() => {
        const description = searchParams.description;
        if (description !== undefined) {
            setPrompt(description);
            generate();
            // const nextSearchParams = new URLSearchParams(searchParams.toString());
            // nextSearchParams.delete("description");
            navigate({ search: () => ({ description: undefined }) });
            // router.update({se})
            // router.replace(`${pathname}?${nextSearchParams}`);
        }
    }, [generate, searchParams, setPrompt, pathname, router, navigate]);

    const startOver = useCallback(() => {
        setActivePrompt("");
        trackEvent("Restart");
    }, [trackEvent]);

    useEffect(() => {
        if (showDetailsView === true) {
            trackEventOnce("Viewed details");
        }
    }, [showDetailsView, trackEventOnce]);

    useEffect(() => {
        if (problemRef.current !== null) {
            const element = problemRef.current;
            element.style.height = "5px";
            element.style.height = element.scrollHeight + "px";
        }
    }, [prompt]);

    const hasActivePrompt = activePrompt.trim().length > 0;

    return (
        <main className="h-svh">
            <AnimatePresence>
                {activePrompt.trim().length === 0 && (
                    <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                        <motion.div
                            className="max-w-sm flex flex-col w-full"
                            variants={boxVariant}
                            animate="visible"
                            initial="hidden"
                            exit="hidden"
                            onAnimationComplete={() => problemRef.current?.select()}
                        >
                            <motion.div
                                variants={itemVariants}
                                className="text-gray-900 text-lg text-center tracking-tight -mt-36"
                            >
                                Describe the app you want to create:
                            </motion.div>
                            <motion.div
                                variants={itemVariants}
                                className="relative flex flex-col items-start mt-6 py-4 px-4 w-full rounded-3xl bg-black shadow-2xl shadow-black/30 outline outline-1 outline-white/5"
                            >
                                <textarea
                                    ref={problemRef}
                                    value={prompt}
                                    autoFocus
                                    className="p-2 w-full bg-transparent text-white text-lg resize-none border-transparent focus:border-transparent focus:ring-0 placeholder-white placeholder-opacity-50 !outline-none data-hj-allow min-h-[100px]"
                                    disabled={busy}
                                    onKeyDown={e => {
                                        if (e.key === "Enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            generate();
                                        }
                                    }}
                                    onChange={e => {
                                        setPrompt(e.target.value);
                                    }}
                                ></textarea>
                                {placeholderAnimating && (
                                    <div
                                        className={classNames(
                                            "p-2 text-lg text-white transition-opacity duration-200 absolute pointer-events-none",
                                            {
                                                "opacity-60": !placeholderWillFade,
                                                "opacity-0": placeholderWillFade,
                                            }
                                        )}
                                    >
                                        {placeholder.split(" ").map((word, i) => (
                                            <motion.span
                                                key={i}
                                                className="inline-block"
                                                initial={{ opacity: 0, y: 5 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.1, delay: 0, ease: "easeOut" }}
                                            >
                                                {word}
                                                {"\u00A0"}
                                            </motion.span>
                                        ))}
                                    </div>
                                )}
                                <PrimaryButton
                                    className={`w-full transition-opacity !opacity-0 ${
                                        prompt.trim().length > 0 ? "!opacity-100" : ""
                                    }`}
                                    onClick={generate}
                                    disabled={busy || prompt.trim().length === 0}
                                >
                                    Generate app
                                </PrimaryButton>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 5, delay: 1, ease: "linear" }}
                                    className="absolute top-1/2 transform -translate-y-1/2 w-full h-[350%] -z-10"
                                >
                                    <motion.div
                                        initial={{ rotate: -90, scale: 1.2 }}
                                        animate={{ rotate: 270, opacity: [0.5, 1, 0.75, 0.5] }}
                                        transition={{ repeat: Number.POSITIVE_INFINITY, duration: 9, ease: "linear" }}
                                        className="absolute inset-0 w-full h-full bg-gradient-radial from-aqua-500/80 to-transparent dark:opacity-50 blur-2xl"
                                    ></motion.div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {hasActivePrompt && (
                    <motion.div
                        className="absolute inset-0 flex items-center sm:px-4 md:px-6 xl:px-24"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeOut", delay: 0.3 }}
                    >
                        <AnimatePresence>{hasTabsMetadata && <TabsEditor onRestart={startOver} />}</AnimatePresence>
                        <App prompt={activePrompt} finishedGenerating={finishedGenerating} onRestart={startOver} />
                        <PexelsAttribution />
                    </motion.div>
                )}
            </AnimatePresence>
            <div
                data-shows-device={hasActivePrompt}
                className="flex absolute justify-start pt-4 md:pt-6 px-8 sm:px-4 md:px-6 xl:px-24 text-gray-900 data-[shows-device=true]:max-lg:hidden"
            >
                <svg
                    className="w-[72px]"
                    width="122"
                    height="38"
                    viewBox="0 0 122 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M58.1002 1.51412C57.1893 2.26655 56.5765 3.31869 56.3717 4.48178C56.0167 6.91305 57.6902 8.78853 60.1258 8.78853C62.6111 8.78853 64.6917 7.11644 65.0467 4.78451C65.4514 2.30121 63.778 0.376056 61.3424 0.376056C60.1606 0.358204 59.0112 0.761688 58.1002 1.51412ZM37.0478 21.898C37.1067 20.7621 37.0893 19.6235 36.9957 18.49H18.4103L15.2622 24.9584H29.1019C28.0486 28.6928 24.1005 31.0578 19.3665 31.0578C12.2065 31.0578 7.47252 26.2733 7.47252 19.4904C7.47252 12.8635 12.3153 8.02699 18.7866 8.02699C23.6815 8.02699 26.5763 10.5458 28.0533 13.495H36.101C34.153 6.02853 27.8924 0.979149 18.7866 0.979149C7.94592 0.979149 0 8.86659 0 19.4904C0 30.2159 7.84177 37.9993 19.3665 37.9803C29.9469 37.9803 36.6265 30.4689 37.0478 21.898ZM43.9925 1.55858L38.3094 37.4198H45.5736L51.2544 1.55858H43.9925ZM51.936 37.4198L55.8297 12.7051H63.0916L59.1979 37.4198H51.936ZM95.5049 1.55858H88.243L86.0299 15.387C84.7162 13.443 82.3492 12.128 78.929 12.128C73.1394 12.128 66.9852 16.0185 65.7189 24.0124C64.1922 33.6854 70.5073 37.9993 75.4022 37.9993C78.6662 37.9993 81.3504 36.6819 83.244 34.3169L84.8109 37.4198H92.8728L91.0597 30.8237L95.5049 1.55858ZM79.613 18.4379C82.4013 18.4379 85.4003 20.9614 84.7162 25.3794C83.9801 30.1118 80.4012 31.6893 77.8733 31.6893C74.5595 31.6893 72.1405 28.8489 72.7701 24.7999C73.35 20.9614 76.1383 18.4379 79.613 18.4379ZM121.608 27.4298H103.029C103.82 30.5848 106.031 32.1599 109.082 32.1599C111.872 32.1599 113.342 31.4764 114.346 29.951H120.924C119.345 35.6815 114.135 37.9922 109.24 37.9922C101.399 37.9922 96.2954 32.4698 96.2954 25.0553C96.2954 17.6953 101.557 12.1209 109.03 12.1209C116.502 12.1209 121.662 17.7024 121.662 25.0624V25.0629C121.662 26.063 121.662 26.4296 121.608 27.4298ZM102.977 22.591H115.035C114.561 19.909 112.033 18.017 109.034 18.017C105.872 18.017 103.673 19.857 102.977 22.591Z"
                        fill="currentColor"
                    />
                </svg>
            </div>
            {!isStandalone && (
                <div className="absolute top-4 right-4">
                    <solid.XMarkIcon
                        className="w-7 h-7 rounded-full bg-gray-300 p-1 text-gray-600 hover:text-gray-700 hover:bg-gray-400 transition-colors cursor-pointer"
                        onClick={builder.close}
                    />
                </div>
            )}
        </main>
    );
};
