import { useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { useGoToBuilder } from "../hooks/useGoToBuilder.js";
import { PrimaryButton } from "./PrimaryButton.jsx";

interface Props {
    isPrimary?: boolean;
    className?: string;
}

export const GoToBuilderButton: React.FC<Props> = props => {
    const { isPrimary = false, className } = props;
    const [isExporting, setIsExporting] = React.useState(false);
    const { tabs } = useAtomValue(atoms.app);
    const isGenerating = tabs.some(t => t.items.length === 0);

    const goToBuilder = useGoToBuilder();

    const didClick = React.useCallback(async () => {
        setIsExporting(true);
        await goToBuilder();
        setIsExporting(false);
    }, [goToBuilder]);

    const label = isGenerating ? "Generating app..." : isExporting ? "Exporting..." : "Create this app";

    if (isPrimary) {
        return (
            <PrimaryButton onClick={didClick} disabled={isGenerating || isExporting} className={className}>
                {label}
            </PrimaryButton>
        );
    }

    return (
        <button className={className} onClick={didClick} disabled={isGenerating || isExporting}>
            {label}
        </button>
    );
};
