import { itemFields, ItemSpec } from "@glide/appgpt-common";
import classNames from "classnames";
import { PrimitiveAtom, useAtom, useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { DynamicHeroIcon } from "../DynamicHeroIcon.jsx";

export function useLayoutProperty<T>(atom: PrimitiveAtom<ItemSpec>, property: string) {
    const [item, updateItem] = useAtom(atom);
    const value = item[property];
    const setValue = (value: T) => updateItem({ ...item, [property]: value });
    return [value, setValue];
}

export function usePrimaryFieldValue(atom: PrimitiveAtom<ItemSpec>) {
    const item = useAtomValue(atom);
    const [primaryField] = itemFields(item);
    return primaryField;
}

const TaskItem: React.FC<{ atom: PrimitiveAtom<ItemSpec>; onClick: () => void }> = props => {
    const primaryField = usePrimaryFieldValue(props.atom);
    const [status, setStatus] = useLayoutProperty(props.atom, "status");

    const completed = status === "Completed";
    const check = <DynamicHeroIcon name="CheckIcon" className="w-4 h-4 p-px text-white" type="solid" />;

    return (
        <div
            className="flex px-4 py-4 space-x-3 items-center cursor-pointer transition hover:bg-black/10"
            onClick={props.onClick}
        >
            <div
                className={classNames(
                    "text-aqua-600 w-6 h-6 p-px flex items-center justify-center shrink-0 rounded-full border-2 border-aqua-600 ",
                    {
                        "bg-aqua-600 transition-none": completed,
                    }
                )}
                onClick={e => {
                    e.stopPropagation();
                    setStatus(completed ? "Not started" : "Completed");
                }}
            >
                {completed && check}
            </div>
            <div className="grow">
                <div className="font-medium line-clamp-1">{primaryField?.value}</div>
            </div>
        </div>
    );
};

export const TaskList = () => {
    const { name } = useAtomValue(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [, setSelectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const [, setShowDetailsView] = useAtom(atoms.showDetailsView);

    return (
        <div className="flex flex-col">
            {itemsAtoms.map((item, i) => (
                <TaskItem
                    key={name + i}
                    atom={item}
                    onClick={() => {
                        setSelectedItemIndex(i);
                        setShowDetailsView(true);
                    }}
                />
            ))}
        </div>
    );
};
TaskList.displayName = "checklist";
