import {
    AppSpec,
    clientPrompts as prompts,
    emptyApp,
    emptyTab,
    ItemType,
    log,
    logDuration,
    Model,
    Options,
    tabIcons,
    TabNoIconNoItemsSpec,
    TabSpec,
    TabStartSpec,
} from "@glide/appgpt-common";

const defaultOptions: Options = {
    model: Model.Default,
    streaming: false,
};

// This is like SetAtom in jotai, but it's not exported
type Mutate<T> = (mutate: T | ((t: T) => T)) => void;

function applyMutate<T>(mutate: T | ((t: T) => T), t: T): T {
    return typeof mutate === "function" ? (mutate as any)(t) : mutate;
}

function setTab(setApp: Mutate<AppSpec>, name: string): Mutate<TabSpec> {
    return mutate => {
        setApp(app => {
            const tabs = app.tabs.map(t => (t.name === name ? applyMutate(mutate, t) : t));
            return { ...app, tabs };
        });
    };
}

function getTabMetadataFromItemType(name: string, itemType: ItemType): TabNoIconNoItemsSpec | undefined {
    switch (itemType) {
        case "person": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "fullName",
                        valueType: "string",
                    },
                    {
                        name: "jobTitle",
                        valueType: "string",
                    },
                    {
                        name: "email",
                        valueType: "email",
                    },
                    {
                        name: "gender",
                        valueType: "gender",
                    },
                ],
                detailsScreenCallToAction: "Contact",
            };
        }
        case "event": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "name",
                        valueType: "string",
                    },
                    {
                        name: "date",
                        valueType: "date",
                    },
                    {
                        name: "location",
                        valueType: "string",
                    },
                ],
                detailsScreenCallToAction: "Add to calendar",
            };
        }
        case "location": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "name",
                        valueType: "string",
                    },
                    {
                        name: "address",
                        valueType: "string",
                    },
                    {
                        name: "city",
                        valueType: "string",
                    },
                ],
                detailsScreenCallToAction: "Get directions",
            };
        }
        case "message": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "sender",
                        valueType: "string",
                    },
                    {
                        name: "message",
                        valueType: "string",
                    },
                    {
                        name: "date",
                        valueType: "string",
                    },
                ],
                detailsScreenCallToAction: "Reply",
            };
        }
        case "notification": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "title",
                        valueType: "string",
                    },
                    {
                        name: "body",
                        valueType: "string",
                    },
                    {
                        name: "date",
                        valueType: "date",
                    },
                ],
                detailsScreenCallToAction: "Archive",
            };
        }
        case "task": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "title",
                        valueType: "string",
                    },
                    {
                        name: "status",
                        valueType: "enum",
                        enumValueOptions: ["Not started", "In progress", "Completed", "Canceled"],
                    },
                    {
                        name: "dueDate",
                        valueType: "date",
                    },
                ],
                detailsScreenCallToAction: "Change status",
            };
        }
        case "product": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "name",
                        valueType: "string",
                    },
                    {
                        name: "price",
                        valueType: "currency",
                    },
                    {
                        name: "stock",
                        valueType: "number",
                    },
                ],
                detailsScreenCallToAction: "Order",
            };
        }
        case "project": {
            return {
                name,
                databaseColumns: [
                    {
                        name: "name",
                        valueType: "string",
                    },
                    {
                        name: "deadline",
                        valueType: "date",
                    },
                    {
                        name: "cost",
                        valueType: "currency",
                    },
                ],
                detailsScreenCallToAction: "Change status",
            };
        }
    }
    return undefined;
}

export class AppGenerator {
    options: Options;

    constructor(options: Partial<Options> = {}) {
        this.options = {
            ...defaultOptions,
            ...options,
        };
    }

    private get model(): Model {
        return this.options.model;
    }

    async generateTabIcons(tabNames: string[], exclude: string[] = []): Promise<string[]> {
        const { icons } = await tabIcons({ tabNames, exclude });
        return icons;
    }

    async generateNewTabIcon(tabName: string, existingTabNames: string[]): Promise<string> {
        const {
            icons: [icon],
        } = await tabIcons({ tabNames: [tabName], exclude: existingTabNames });
        return icon;
    }

    async generateTables(appDescription: string): Promise<{ name: string; itemType: ItemType }[]> {
        const { tables } = await prompts.tableNames.complete({ appDescription }, this.model);
        log("generation", "Tables", tables);
        return tables;
    }

    public async generateTabNames(appDescription: string): Promise<string[]> {
        const { tabNames } = await prompts.tabNames.complete({ appDescription }, this.model);
        return tabNames;
    }

    private async generateTabNoIcon(setTab: Mutate<TabSpec>, appDescription: string, name: string, itemType: ItemType) {
        let layoutAndSchema = getTabMetadataFromItemType(name, itemType);
        if (layoutAndSchema === undefined) {
            // FIXME this code path results in items being empty when app is saved to cache
            layoutAndSchema = await logDuration("generation", `Tab ${name} Schema`, () =>
                this.generateTabNoIconNoItems(appDescription, name)
            );
            log("generation", `Tab ${name} Schema`, layoutAndSchema);
        }

        // We will provide our own image
        layoutAndSchema.databaseColumns = layoutAndSchema.databaseColumns?.filter(c => c.name !== "image") ?? [];

        setTab(tab => ({ ...tab, ...layoutAndSchema }));

        const tabWithoutItems = { ...layoutAndSchema, name };
        const items = await logDuration("generation", `Tab ${name} Items`, () =>
            this.generateItems(appDescription, tabWithoutItems)
        );
        setTab(tab => ({ ...tab, items }));
    }

    public async generateItems(description: string, tab: TabNoIconNoItemsSpec): Promise<any[]> {
        const { items } = await prompts.tabItems.complete({ tab, description }, this.model);
        // TODO figure out how this is becoming undefined
        return items ?? [];
    }

    private async generateTabNoIconNoItems(appDescription: string, tabName: string): Promise<TabNoIconNoItemsSpec> {
        const result = await prompts.tabNoIconNoItems.complete({ appDescription, tabName }, this.model);
        return { ...result, name: tabName };
    }

    async generateAppMetadata(description: string): Promise<{ name: string; icon: string; description: string }> {
        const { appName, appEmojiIcon, appDescription } = await prompts.appMetadata.complete(
            { description },
            this.model
        );
        return { name: appName, icon: appEmojiIcon, description: appDescription };
    }

    public async generate(setApp: Mutate<AppSpec>, description: string) {
        setApp(emptyApp);

        await logDuration("generation", "App Metadata", async () => {
            const { name, icon, description: newDescription } = await this.generateAppMetadata(description);
            setApp(app => {
                const { name: _name, icon: _icon, description: _description, ...noNameOrIcon } = app;
                // Keep name and icon first
                return { name, icon, description: newDescription, ...noNameOrIcon };
            });
        });

        const tabNamesAndTypes = await logDuration("generation", "Tables", () => this.generateTables(description));
        setApp(app => {
            return {
                ...app,
                tabs: tabNamesAndTypes.map((t, i) => ({
                    ...emptyTab,
                    ...t,
                    name: tabNamesAndTypes[i].name,
                    itemType: tabNamesAndTypes[i].itemType,
                })),
            };
        });

        const generateIcons = logDuration("generation", "Tab Icons", async () => {
            const tabNames = tabNamesAndTypes.map(t => t.name);
            const icons = await this.generateTabIcons(tabNames);
            setApp(app => {
                const tabs = app.tabs.map((t, i) => ({ ...t, icon: icons[i] }));
                return { ...app, tabs };
            });
        });

        const generateTab = logDuration("generation", "Generate Tabs", () =>
            Promise.all(
                tabNamesAndTypes.map(t =>
                    this.generateTabNoIcon(setTab(setApp, t.name), description, t.name, t.itemType)
                )
            )
        );

        await Promise.all([generateIcons, generateTab]);
    }

    async generateNewTabStartSpec(appDescription: string, tabName: string): Promise<TabStartSpec> {
        return await prompts.tabStart.complete({ appDescription, tabName }, this.model);
    }

    public async generateNewTab(
        setApp: Mutate<AppSpec>,
        appDescription: string,
        tabName: string,
        index: number,
        usedIcons: string[]
    ) {
        setApp(app => {
            const tabs = [...app.tabs];
            tabs[index] = { ...emptyTab, name: tabName };
            return { ...app, tabs };
        });

        const start = await this.generateNewTabStartSpec(appDescription, tabName);
        setApp(app => {
            const tabs = [...app.tabs];
            tabs[index] = { ...tabs[index], ...start };
            return { ...app, tabs };
        });

        const generateIcons = logDuration("New Tab Icon", async () => {
            const icon = await this.generateNewTabIcon(tabName, usedIcons);
            setApp(app => {
                const tabs = app.tabs.map((t, i) => (i === index ? { ...t, icon } : t));
                return { ...app, tabs };
            });
        });

        const generateTab = logDuration("New Tab data", () =>
            this.generateTabNoIcon(setTab(setApp, tabName), appDescription, tabName, start.itemType)
        );

        await Promise.all([generateIcons, generateTab]);
    }
}
