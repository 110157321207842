import { motion } from "framer-motion";
import { useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { Spinner } from "./Image.jsx";

export const LaunchScreen = () => {
    const app = useAtomValue(atoms.app);
    return (
        <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 1 }}
            transition={{ duration: 0.3, ease: "circOut" }}
            className="absolute inset-0 md:inset-px bg-aqua-800 text-black flex items-center justify-center md:rounded-[46px] oveflow-hidden"
        >
            <div className="flex flex-col items-center gap-7">
                <div className="relative">
                    <img width={97} height={97} alt="" src="/app-icon.svg" />
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <img
                            className="w-20 h-auto"
                            src={`https://functions.prod.internal.glideapps.com/getEmoji/${app.icon}`}
                            alt={""}
                        />
                    </div>
                </div>
                <div className="text-white font-medium text-3xl">{app.name}</div>
            </div>
            <div className="absolute text-white top-3/4">
                <Spinner />
            </div>
        </motion.div>
    );
};
