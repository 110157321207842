import { ItemType } from "@glide/appgpt-common";
import { useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { Grid } from "./Grid.jsx";
import { List } from "./List.jsx";
import { MapList } from "./MapList.jsx";
import { TaskList } from "./TaskList.jsx";

type Layout = React.FC<{}>;
const layouts: Record<ItemType, Layout> = {
    person: Grid,
    location: MapList,
    task: TaskList,
    product: Grid,
    event: List,
    notification: List,
    message: List,
    other: List,
    project: Grid,
};

export function getLayoutNameFromItemType(type: ItemType): string {
    const Layout = layouts[type] ?? List;
    return Layout.displayName ?? "list";
}

export const Collection = () => {
    const tab = useAtomValue(atoms.activeTab);
    const Layout = layouts[tab.itemType] ?? List;
    return (
        <div className="pb-20">
            <Layout />
        </div>
    );
};
