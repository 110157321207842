import React from "react";

import * as builder from "../builder";
import { PrimaryButton } from "./PrimaryButton";

const Paragraph: React.FC<{ children: React.ReactNode }> = p => {
    return <p className="text-gray-900 text-lg tracking-tight text-center">{p.children}</p>;
};

export const ErrorComponentOnboarding: React.FC = () => {
    const doContinue = React.useCallback(() => {
        builder.skipDueToError("JavaScript error");
    }, []);

    return (
        <div className="w-full h-full flex items-center justify-center p-10">
            <div className="flex flex-col gap-8 items-center max-w-[470px]">
                <Paragraph>Oops, something went wrong.</Paragraph>
                <Paragraph>
                    Luckily you can build an app in Glide in minutes starting from scratch, or in seconds if you start
                    from a template.
                </Paragraph>
                <PrimaryButton onClick={doContinue}>Continue</PrimaryButton>
            </div>
        </div>
    );
};
