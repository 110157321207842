import React from "react";

export const PrimaryButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props => {
    const { className, ...rest } = props;
    return (
        <button
            className={`text-white text-center text-lg tracking-tight font-medium py-3 px-8 rounded-full bg-aqua-400 hover:bg-aqua-500 disabled:opacity-50 disabled:gray-200 ${
                className ?? ""
            }`}
            {...rest}
        >
            {props.children}
        </button>
    );
};
