import { imagePrompt, itemFields, ItemSpec } from "@glide/appgpt-common";
import { PrimitiveAtom, useAtom } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { DynamicHeroIcon } from "../DynamicHeroIcon.jsx";
import { Image } from "../Image.jsx";

export const ListItem: React.FC<{ atom: PrimitiveAtom<ItemSpec>; onClick: () => void }> = props => {
    const [activeTab] = useAtom(atoms.activeTab);
    const [item, updateItem] = useAtom(props.atom);
    const [primaryField, secondaryField] = itemFields(item, activeTab.databaseColumns);

    const hasStringTitle = activeTab.databaseColumns[0]?.valueType === "string";

    return (
        <div
            className="flex px-4 py-2 space-x-3 items-center cursor-pointer transition hover:bg-black/10"
            onClick={props.onClick}
        >
            {hasStringTitle && (
                <Image
                    src={item.image}
                    itemType={activeTab.itemType}
                    load={image => updateItem({ ...item, image })}
                    className="object-cover rounded-lg w-12 h-12 bg-black/10 shrink-0"
                    prompt={imagePrompt(item)}
                    alt=""
                />
            )}
            <div className="grow">
                <div className="font-medium text-sm line-clamp-1">{primaryField?.value}</div>
                <div className="opacity-70 text-sm line-clamp-2">{secondaryField?.value}</div>
            </div>
            <DynamicHeroIcon name="ChevronRightIcon" className="text-gray-700 w-5 h-5" type="outline" />
        </div>
    );
};
