"use client";

import { AppSpec, emptyApp, isPWA } from "@glide/appgpt-common";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom, useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { useAnalytics } from "../../analytics/index.js";
import { GlideButton } from "../GlideButton.jsx";
import { Collection } from "./collections/index.jsx";
import { DemoLimitAlert } from "./DemoLimitAlert.jsx";
import { DetailView } from "./detail-views/index.jsx";
import { DynamicHeroIcon } from "./DynamicHeroIcon.jsx";
import { Spinner } from "./Image.jsx";
import { LaunchScreen } from "./LaunchScreen.jsx";
import { PhoneDashboard } from "./PhoneDashboard.jsx";

const Tabs = () => {
    const { tabs } = useAtomValue(atoms.app);
    const [activeTab, setActiveTab] = useAtom(atoms.activeTab);
    const { trackEventOnce } = useAnalytics();

    return (
        <div
            className={classNames(
                "absolute bottom-0 pb-2 flex w-full px-2 _pw_tabs",
                "border-t border-neutral-800/5 bg-white/10 backdrop-blur-md bg-gradient-to-b from-white/50 to-white",
                {
                    "pb-5": isPWA,
                }
            )}
        >
            {tabs.map(tab => {
                return (
                    <div
                        key={tab.name}
                        onClick={() => {
                            setActiveTab(tab);
                            trackEventOnce("Switched tab");
                        }}
                        className={classNames("h-16 rounded-lg flex p-2 pt-3 flex-col _pw_tab", {
                            "text-neutral-700 opacity-60 cursor-pointer transition hover:opacity-100":
                                activeTab?.name !== tab.name,
                            "text-aqua-600": activeTab?.name === tab.name,
                        })}
                        style={{ width: `${100 / tabs.length}%` }}
                    >
                        <div className="icon flex justify-center">
                            <DynamicHeroIcon
                                data-icon={tab.icon}
                                name={(tab.icon as any) ?? "CogIcon"}
                                type="outline"
                                className="text-current _pw_tab_icon"
                            />
                        </div>
                        <div className="grow"></div>
                        <h2 className="text-center text-[10px] truncate">{tab.name}</h2>
                    </div>
                );
            })}
        </div>
    );
};

interface Props {
    prompt: string | undefined;
    finishedGenerating?: (app: AppSpec) => void;
    onRestart?: () => void;
}

export const App: React.FC<Props> = props => {
    const { prompt = "", finishedGenerating, onRestart } = props;
    const [isGenerating, setIsGenerating] = React.useState(false);
    const [activeTab] = useAtom(atoms.activeTab);
    const [app, setApp] = useAtom(atoms.app);
    const generator = useAtomValue(atoms.generator);
    const [, setSelectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const [showDetailsView, setShowDetailsView] = useAtom(atoms.showDetailsView);
    const hasAppMetadata = app.name.trim().length > 0;
    const hasTabsMetadata = app.tabs.some(t => t.databaseColumns.length > 0);
    const hasTabs = activeTab.name.trim().length > 0;
    const noItems = hasTabs && activeTab.items.length === 0;

    const appRef = React.useRef(app);
    appRef.current = app;

    const generate = React.useCallback(async () => {
        if (prompt === "") {
            setApp(emptyApp);
            return;
        }
        setShowDetailsView(false);
        setSelectedItemIndex(0);

        function finished() {
            setIsGenerating(false);
            finishedGenerating?.(appRef.current!);
        }

        setIsGenerating(true);
        await generator.generate(setApp, prompt);

        finished();
    }, [prompt, generator, finishedGenerating, setApp, setShowDetailsView, setSelectedItemIndex]);

    React.useEffect(() => {
        void generate();
    }, [generate]);

    return (
        <>
            <div className="relative grow flex items-center justify-center text-neutral-800 h-full _pw_app">
                <div className="md:relative md:w-[409px] md:h-[800px] md:border-8 md:border-neutral-200/40 md:outline md:outline-1 md:outline-neutral-800/10 md:shadow-2xl md:shadow-neutral-800/20 md:rounded-[56px] md:overflow-hidden">
                    <PhoneDashboard isGenerating={isGenerating} />
                    <AnimatePresence>{hasAppMetadata && <LaunchScreen />}</AnimatePresence>
                    <AnimatePresence>
                        {hasTabsMetadata && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 1 }}
                                className="absolute inset-0 md:pt-8 pb-0 bg-white"
                            >
                                <div className="flex flex-col max-h-full">
                                    <nav className="h-12 px-2 flex items-center shrink-0">
                                        <button
                                            className={`p-2 ${showDetailsView ? "text-aqua-600" : "text-gray-500"}`}
                                            onClick={showDetailsView ? () => setShowDetailsView(false) : undefined}
                                            disabled={!showDetailsView}
                                        >
                                            <DynamicHeroIcon
                                                name={showDetailsView ? "ArrowLeftIcon" : "Bars3Icon"}
                                                type="outline"
                                            />
                                        </button>
                                    </nav>
                                    <section
                                        className="relative flex flex-col grow overflow-y-auto"
                                        key={activeTab.name}
                                    >
                                        <motion.div
                                            animate={{ opacity: showDetailsView ? 0 : 1 }}
                                            className="max-h-full overflow-y-auto"
                                        >
                                            <h1 className="text-[22px] font-semibold mx-4">{activeTab.name}</h1>
                                            {noItems ? (
                                                <div className="h-[558px] flex items-center justify-center _pw_spinner">
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                <Collection />
                                            )}
                                        </motion.div>
                                        <AnimatePresence>{showDetailsView && <DetailView />}</AnimatePresence>
                                    </section>
                                </div>
                                <Tabs />
                                <span className="hidden md:absolute inset-0 border border-gray-700/15 rounded-[48px] pointer-events-none"></span>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <DemoLimitAlert />
                </div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 6, delay: 8, ease: "linear" }}
                    className="absolute -z-10 inset-12 bg-gradient-radial opacity-0 from-aqua-500/80 to-transparent dark:opacity-50 blur-2xl"
                ></motion.div>
            </div>
            {hasTabsMetadata && <GlideButton onClick={onRestart} />}
        </>
    );
};
