import { imagePrompt, itemFields, ItemSpec } from "@glide/appgpt-common";
import { PrimitiveAtom, useAtom, useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { Image } from "../Image.jsx";

const GridItem: React.FC<{ atom: PrimitiveAtom<ItemSpec>; onClick: () => void }> = props => {
    const [activeTab] = useAtom(atoms.activeTab);
    const [item, updateItem] = useAtom(props.atom);
    const [primaryField, secondaryField] = itemFields(item, activeTab.databaseColumns);

    const hasStringTitle = activeTab.databaseColumns[0]?.valueType === "string";

    return (
        <div className="flex flex-col cursor-pointer" onClick={props.onClick}>
            {hasStringTitle && (
                <Image
                    src={item.image}
                    itemType={activeTab.itemType}
                    gender={item.gender}
                    load={image => updateItem({ ...item, image })}
                    prompt={imagePrompt(item)}
                    className="rounded-lg w-full h-36 bg-black/10 shrink-0 object-cover pointer-events-none"
                    alt=""
                />
            )}
            <div className="font-medium text-sm line-clamp-1 ml-1 mt-1">{primaryField?.value}</div>
            <div className="opacity-70 text-sm line-clamp-1 ml-1">{secondaryField?.value}</div>
        </div>
    );
};

export const Grid = () => {
    const { name } = useAtomValue(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [, setSelectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const [, setShowDetailsView] = useAtom(atoms.showDetailsView);

    return (
        <div className="grid grid-cols-2 gap-4 p-4">
            {itemsAtoms.map((item, i) => (
                <GridItem
                    key={name + i}
                    atom={item}
                    onClick={() => {
                        setSelectedItemIndex(i);
                        setShowDetailsView(true);
                    }}
                />
            ))}
        </div>
    );
};
Grid.displayName = "grid";
