import { emptyApp, Model, TabSpec } from "@glide/appgpt-common";
import { atom } from "jotai";
import { atomWithStorage, splitAtom } from "jotai/utils";
import { withImmer } from "jotai-immer";
import { focusAtom } from "jotai-optics";

import { AppGenerator } from "./AppGenerator.js";

export const app = withImmer(atom(emptyApp));

export const activeTabName = atom<string | undefined>(undefined);

export const selectedItemIndex = atom<number>(0);
export const showDetailsView = atom(false);

export const activeTab = atom<TabSpec, TabSpec[], void>(
    get => {
        const { tabs } = get(app);
        const activeTab = tabs.find(tab => tab.name === get(activeTabName));
        return activeTab ?? tabs[0];
    },
    (get, set, newTab) => {
        const appValue = get(app);
        const tabs = appValue.tabs.map(tab => (tab.name === newTab.name ? newTab : tab));
        set(activeTabName, newTab.name);
        set(app, { ...appValue, tabs });
        set(showDetailsView, false);
    }
);

export type Props = {
    prompt: string;
    model: Model;
};

export const editor = atomWithStorage<Props>("editor-1", {
    prompt: "",
    model: Model.Default,
});

export const prompt = focusAtom(editor, optic => optic.prop("prompt"));
export const model = focusAtom(editor, optic => optic.prop("model"));
export const streaming = atom(false);

const activeTabItems = focusAtom(activeTab, optic => optic.prop("items"));
export const activeTabItemsAtom = splitAtom(activeTabItems);

export const generator = atom(get => new AppGenerator({ model: get(model), streaming: get(streaming) }));
