import { isStandalone, toTitleCase } from "@glide/appgpt-common";
import * as outline from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useAtom, useAtomValue } from "jotai";
import React, { createElement } from "react";

import * as atoms from "@/atoms.js";

import { useAnalytics } from "../analytics/index.js";
import { useGoToBuilder } from "../hooks/useGoToBuilder.js";
import { GoToBuilderButton } from "./GoToBuilderButton.jsx";
import { PrimaryButton } from "./PrimaryButton.jsx";

const EditableTab: React.FC<{
    index: number;
    isNew?: boolean;
    onSaved?: (name: string) => void;
    onCancelSaving?: () => void;
}> = props => {
    const { index, isNew = false, onSaved, onCancelSaving } = props;
    const { tabs } = useAtomValue(atoms.app);
    const [_, setApp] = useAtom(atoms.app);
    const tab = tabs[index];
    const [newTabName, setNewTabName] = React.useState(() => {
        let name = "New tab";
        let attempts = 0;
        while (tabs.map(t => t.name.toLowerCase()).includes(name.toLowerCase())) {
            attempts++;
            name = `New tab ${attempts}`;
        }
        return name;
    });
    const inputFieldRef = React.useRef<HTMLInputElement>(null);
    const { trackEvent } = useAnalytics();

    const remove = React.useCallback(() => {
        trackEvent("Remove tab", { tabName: tab.name });
        setApp(app => ({ ...app, tabs: app.tabs.filter((t, i) => i !== index) }));
    }, [index, setApp, tab, trackEvent]);

    const canBeDeleted = tabs.length > 1;

    const canSave = React.useMemo(
        () => !tabs.map(t => t.name.toLowerCase()).includes(newTabName.toLocaleLowerCase()),
        [tabs, newTabName]
    );

    const onKeyDown = React.useCallback(
        async (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && canSave) {
                event.preventDefault();
                const tabName = toTitleCase(newTabName);
                onSaved?.(tabName);
                trackEvent("Add tab", { tabName });
            } else if (event.key === "Escape") {
                event.preventDefault();
                onCancelSaving?.();
            }
        },
        [newTabName, onSaved, onCancelSaving, canSave, trackEvent]
    );

    React.useEffect(() => {
        if (isNew && inputFieldRef.current !== null) {
            inputFieldRef.current.focus();
            inputFieldRef.current.select();
        }
    }, [isNew]);

    if (tab === undefined && !isNew) return null;

    let icon = isNew ? outline.EllipsisHorizontalIcon : outline[tab.icon as keyof typeof outline];
    if (icon === undefined) {
        icon = outline.QuestionMarkCircleIcon;
    }
    return (
        <div className="flex items-center gap-2 py-2 px-3 pr-2 rounded-full border border-gray-300 text-gray-800 text-[.9375rem] leading-[140%]">
            {createElement(icon, { className: "w-5 h-5 text-gray-700" })}
            {isNew ? (
                <input
                    ref={inputFieldRef}
                    className="!outline-none bg-transparent data-hj-allow"
                    type="text"
                    value={newTabName}
                    onChange={e => setNewTabName(e.target.value)}
                    onKeyDown={onKeyDown}
                    onBlur={onCancelSaving}
                />
            ) : (
                <>
                    <div className="">{tab.name}</div>
                    {canBeDeleted && (
                        <button
                            onClick={remove}
                            className="text-gray-700 hover:text-gray-800 p-0.5 hover:bg-gray-200 rounded-full"
                        >
                            <outline.XMarkIcon className="w-4 h-4" />
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

interface Props {
    onRestart: () => void;
}

export const TabsEditor: React.FC<Props> = props => {
    const { onRestart } = props;
    const [app, setApp] = useAtom(atoms.app);
    const prompt = useAtomValue(atoms.prompt);
    const { tabs } = useAtomValue(atoms.app);
    const [isAddingTab, setIsAddingTab] = React.useState(false);
    const generator = useAtomValue(atoms.generator);
    const [, setActiveTabName] = useAtom(atoms.activeTabName);
    const isGenerating = tabs.some(t => t.items.length === 0);

    const goToBuilder = useGoToBuilder();

    const addTab = React.useCallback(
        async (name: string) => {
            const usedIcons = tabs.map(t => t.icon);
            void generator.generateNewTab(setApp, prompt, name, tabs.length, usedIcons);
            setIsAddingTab(false);
            setActiveTabName(name);
        },
        [generator, setApp, tabs, prompt, setActiveTabName]
    );

    return (
        <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: 436, opacity: 1 }}
            exit={{ width: 436, opacity: 1 }}
            transition={{ opacity: { delay: 0.5 }, width: { ease: "easeInOut", duration: 0.5 } }}
            className="hidden lg:flex flex-col items-center text-gray-900 text-xl leading-snug"
        >
            <div className="flex flex-col items-start gap-10 w-full max-w-md">
                <div className="flex items-center gap-3 max-w-full">
                    <div className="w-12 h-12 rounded-xl shrink-0 flex items-center justify-center bg-aqua-600">
                        <img
                            className="w-9 h-auto"
                            src={`https://functions.prod.internal.glideapps.com/getEmoji/${app.icon}`}
                            alt={""}
                        />
                    </div>
                    <div className="grow">
                        <div className="font-medium text-lg">{app.name}</div>
                        <div className="text-sm	text-gray-700">{app.description}</div>
                    </div>
                </div>
                <div className="flex flex-col items-start gap-5 ">
                    <p className="text-xl tracking-tight">
                        Here&apos;s a preview of your app. You can try it and change the tabs.
                    </p>
                    <div className="flex flex-col items-start gap-2">
                        {tabs.map((tab, index) => {
                            return <EditableTab key={tab.name} index={index} />;
                        })}
                        {isAddingTab ? (
                            <EditableTab
                                index={tabs.length}
                                isNew={true}
                                onSaved={addTab}
                                onCancelSaving={() => setIsAddingTab(false)}
                            />
                        ) : (
                            <button
                                className="flex items-center gap-2 pl-[0.8125rem] pr-4 py-2 border bg-gray-200 border-transparent disabled:opacity-30 rounded-full transition hover:bg-gray-300"
                                onClick={() => setIsAddingTab(true)}
                                disabled={tabs.length > 4}
                            >
                                <outline.PlusIcon className="w-5 h-5 text-gray-700" />
                                <div className="text-gray-800 text-[.9375rem] leading-[140%]">New tab</div>
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-full">
                    {isStandalone ? (
                        <>
                            <PrimaryButton onClick={onRestart}>Create different app</PrimaryButton>
                            {import.meta.env.DEV && (
                                <button
                                    onClick={goToBuilder}
                                    className="text-base opacity-70 hover:opacity-100 disabled:opacity-0"
                                    disabled={isGenerating}
                                >
                                    Test go to Builder
                                </button>
                            )}
                        </>
                    ) : (
                        <>
                            <GoToBuilderButton className="w-full" isPrimary={true} />
                            <button
                                onClick={onRestart}
                                className="text-base opacity-70 hover:opacity-100 disabled:opacity-0"
                                disabled={isGenerating}
                            >
                                Create a different app
                            </button>
                        </>
                    )}
                </div>
            </div>
        </motion.div>
    );
};
