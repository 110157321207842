import { camelCaseToTitle, formatColumnValue } from "@glide/appgpt-common";
import { atom, useAtom } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { useShowDemoAlert } from "../DemoLimitAlert.jsx";

const reservedKeys = new Set(["type", "image"]);

export const OtherDetailsView = () => {
    const [activeTab] = useAtom(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [selectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const [item, updateItem] = useAtom(itemsAtoms[selectedItemIndex] ?? atom(undefined));
    const showDemoAlert = useShowDemoAlert();

    const isCompletedTask = activeTab.itemType === "task" && item["status"] === "Completed";

    const didClickPrimaryButton = React.useCallback(() => {
        if (activeTab.itemType === "task") {
            if (isCompletedTask) {
                updateItem({
                    ...item,
                    status: "Not started",
                });
            } else {
                updateItem({
                    ...item,
                    status: "Completed",
                });
            }
            return;
        }
        showDemoAlert();
    }, [activeTab.itemType, showDemoAlert, isCompletedTask, item, updateItem]);

    const formatValue = React.useCallback(
        (key: string) => formatColumnValue(key, activeTab.databaseColumns, item[key]),
        [activeTab.databaseColumns, item]
    );

    if (item === undefined) {
        return null;
    }

    const fields = Object.keys(item)
        .filter(key => !reservedKeys.has(key))
        .map(key => ({
            label: camelCaseToTitle(key),
            value: item[key],
            key,
        }));

    const title = formatValue(fields[0].key);
    const primaryButtonLabel =
        activeTab.itemType === "task"
            ? isCompletedTask
                ? "Mark as not started"
                : "Mark as completed"
            : activeTab.detailsScreenCallToAction;

    return (
        <>
            {item.image && (
                <figure className="px-3">
                    <img
                        src={item.image}
                        className="object-cover rounded-lg overflow-hidden w-full max-h-60 shadow-sm"
                        alt={title}
                    />
                </figure>
            )}
            <h1 className="my-4 px-4 text-2xl font-semibold">{title}</h1>
            <section className="my-4 px-4">
                <dl className="divide-y divide-black/10 text-sm">
                    {fields.map((field, i) => (
                        <div key={field.toString() + i} className="grid grid-cols-3 py-2 gap-3">
                            <dt className="text-gray-500">{field.label}</dt>
                            <dd className="col-span-2">{formatValue(field.key)}</dd>
                        </div>
                    ))}
                </dl>
            </section>
            <section className="my-4 px-4">
                <button
                    className="p-3 w-full bg-aqua-600 rounded-lg font-semibold text-white text-sm transition hover:opacity-90 active:opacity-80"
                    onClick={didClickPrimaryButton}
                >
                    {primaryButtonLabel}
                </button>
            </section>
        </>
    );
};
