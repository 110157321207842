import { isPWA } from "@glide/appgpt-common";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

interface Props {
    onClick?: () => void;
}

export const GlideButton: React.FC<Props> = props => {
    const { onClick } = props;

    if (!isPWA) return null;

    return (
        <AnimatePresence>
            <motion.div
                onClick={onClick}
                drag
                dragTransition={{ bounceStiffness: 1000, bounceDamping: 10 }}
                whileDrag={{ scale: 1.2, shadow: "xl" }}
                className="flex items-center justify-center w-14 h-14 shadow-2xl absolute bottom-24 right-4 rounded-full bg-black"
            >
                <svg width="30" height="30" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 55L55 0V27C55 42.464 42.464 55 27 55H0Z" fill="white" />
                    <path d="M45 73C45 57.536 57.536 45 73 45H100L45 100V73Z" fill="white" />
                </svg>
            </motion.div>
        </AnimatePresence>
    );
};
