"use client";
import { ItemType, showAvatarForListItem } from "@glide/appgpt-common";
import axios from "axios";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import { useOnce } from "../hooks.js";

export const Spinner = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="currentColor"
            d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            opacity=".25"
        />
        <path
            fill="currentColor"
            d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                dur="0.75s"
                values="0 12 12;360 12 12"
                repeatCount="indefinite"
            />
        </path>
    </svg>
);

export const Image: React.FC<
    {
        prompt: string;
        itemType?: ItemType;
        gender?: "male" | "female";
        load?: (image: string) => void;
    } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ prompt, itemType = "other", gender, load, className, src, ...rest }) => {
    const [image, setImage] = useState(src);

    const isMounted = React.useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getImage = useCallback(async () => {
        const showAvatar = showAvatarForListItem(itemType);
        const apiPath = showAvatar ? `/api/images/createAvatar` : `/api/images/create`;
        const res = await axios.get<{ url: string }>(apiPath, {
            params: { prompt: encodeURIComponent(prompt), type: itemType, gender },
        });
        const { url } = res.data as any;
        if (isMounted.current) {
            load?.(url);
            setImage(url);
        }
    }, [prompt, load, itemType, gender]);

    useOnce(() => {
        if (image !== undefined) return;
        void getImage();
    });

    if (image === undefined) {
        return (
            <div className={classNames(className, "flex items-center justify-center text-gray-400")}>
                <Spinner />
            </div>
        );
    }

    return <img {...rest} className={className} src={image} key={prompt} title={prompt} alt={prompt} />;
};
