import { ItemType } from "@glide/appgpt-common";
import { motion } from "framer-motion";
import { useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { LocationDetailsView } from "./LocationDetailsView.jsx";
import { OtherDetailsView } from "./OtherDetailsView.jsx";
import { PersonDetailsView } from "./PersonDetailsView.jsx";

type Layout = React.FC<{}>;
const layouts: Record<ItemType, Layout> = {
    person: PersonDetailsView,
    location: LocationDetailsView,
    task: OtherDetailsView,
    product: OtherDetailsView,
    event: OtherDetailsView,
    notification: OtherDetailsView,
    message: OtherDetailsView,
    project: OtherDetailsView,
    other: OtherDetailsView,
};

export const DetailView = () => {
    const tab = useAtomValue(atoms.activeTab);

    const Layout = layouts[tab.itemType] ?? OtherDetailsView;
    return (
        <motion.div
            initial={{ opacity: 0, x: 393 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 393 }}
            transition={{ duration: 0.15, ease: "circOut" }}
            className="bg-white absolute inset-0"
        >
            <Layout />
        </motion.div>
    );
};
