import { isStandalone } from "@glide/appgpt-common";
import { AnimatePresence, motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import React from "react";

import { GoToBuilderButton } from "../GoToBuilderButton.jsx";

const showAlertAtom = atom(false);

export const useShowDemoAlert = () => {
    const [, setShowAlert] = useAtom(showAlertAtom);
    const showDemoAlert = React.useCallback(() => {
        setShowAlert(true);
    }, [setShowAlert]);
    return showDemoAlert;
};

const buttonClassName =
    "p-3 w-full bg-aqua-600 rounded-lg font-semibold text-white text-sm transition hover:opacity-90 disabled:pointer-events-none active:opacity-80";

export const DemoLimitAlert = () => {
    const [showAlert, setShowAlert] = useAtom(showAlertAtom);

    return (
        <AnimatePresence>
            {showAlert && (
                <div className="absolute inset-0 flex items-center justify-center px-2 z-10">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 bg-black/30"
                        onClick={() => setShowAlert(false)}
                    />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.7 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.7 }}
                        className="bg-white rounded-xl p-4 relative"
                    >
                        {isStandalone ? (
                            <>
                                <h3 className="text-lg font-semibold mb-1">Add actions in Glide</h3>
                                <div className="mb-4">
                                    To make this app edit your data or run automations the way you want, create it in
                                    Glide.
                                </div>
                                <button
                                    className={buttonClassName}
                                    onClick={() => window.open("https://go.glideapps.com/")}
                                >
                                    Try Glide
                                </button>
                            </>
                        ) : (
                            <>
                                <h3 className="text-lg font-semibold mb-1">Add actions in the builder</h3>
                                <div className="mb-4">
                                    To make this app edit your data or run automations the way you want, continue the
                                    editing in the builder.
                                </div>
                                <GoToBuilderButton className={buttonClassName} />
                            </>
                        )}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};
