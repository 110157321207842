import { eventName } from "@glide/appgpt-common";
import { useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

let isInitialized = false;

function initialize() {
    if (isInitialized) return;
    isInitialized = true;
}

const trackedEvents: Record<string, boolean> = {};

export function trackEvent(name: string, props: object = {}) {
    initialize();

    if (import.meta.env.DEV) {
        console.log("Mock log", name, props);
        return;
    }
}

export const useAnalytics = () => {
    const prompt = useAtomValue(atoms.prompt);

    const trackEventWithPrompt = React.useCallback(
        (name: string, data: object = {}) => {
            name = eventName(name);
            const props = {
                ...data,
                prompt,
            };
            if (import.meta.env.DEV) {
                console.log("Mock log", name, props);
                return;
            }
            trackEvent(name, props);
        },
        [prompt]
    );

    const trackEventOnce = React.useCallback(
        (name: string, data: object = {}) => {
            if (trackedEvents[name] !== true) {
                trackEventWithPrompt(name, data);
                trackedEvents[name] = true;
            }
        },
        [trackEventWithPrompt]
    );
    return { trackEvent: trackEventWithPrompt, trackEventOnce };
};
