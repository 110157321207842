import axios from "axios";
import { useAtom, useAtomValue } from "jotai";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { Map as MapGL, Marker } from "react-map-gl";

import * as atoms from "@/atoms.js";

import { ListItem } from "./ListItem.jsx";

interface MarkerData {
    longitude: number;
    latitude: number;
}

const MapComponent: React.FC<{ addresses: string[] }> = ({ addresses }) => {
    const mapRef = useRef<any>();

    const [viewport, setViewport] = useState({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 11,
        style: { width: "100%", height: 240, borderRadius: 12 },
    });

    const [markers, setMarkers] = useState<MarkerData[]>([]);

    useEffect(() => {
        const geocodeAddresses = async () => {
            try {
                const newMarkers = await Promise.all(
                    addresses.map(async address => {
                        const response = await axios.get(
                            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                                address
                            )}.json?access_token=${import.meta.env.VITE_PUBLIC_MAPBOX_TOKEN}`
                        );
                        const { center } = response.data.features[0];
                        return { longitude: center[0], latitude: center[1] };
                    })
                );
                setMarkers(newMarkers);
                setViewport({ ...viewport });
            } catch (error) {
                console.error("Error during geocoding:", error);
            }
        };

        void geocodeAddresses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses]);

    useEffect(() => {
        if (mapRef.current === null) return;

        const bounds = new mapboxgl.LngLatBounds();

        markers.forEach(marker => {
            bounds.extend([marker.longitude, marker.latitude]);
        });

        mapRef.current.getMap().fitBounds(bounds, {
            padding: 20,
            maxZoom: 15,
            duration: 2000,
        });
    }, [markers]);

    return (
        <MapGL
            ref={mapRef}
            {...viewport}
            mapboxAccessToken={import.meta.env.VITE_PUBLIC_MAPBOX_TOKEN}
            // onViewportChange={nextViewport => setViewport(nextViewport)}
            mapStyle="mapbox://styles/mapbox/light-v11"
        >
            {markers.map((marker, index) => (
                <Marker key={index} longitude={marker.longitude} latitude={marker.latitude} color="black" />
            ))}
        </MapGL>
    );
};

export const MapList = () => {
    const { name, items } = useAtomValue(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [, setSelectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const [, setShowDetailsView] = useAtom(atoms.showDetailsView);

    const addresses = items.map(item => item.address);

    return (
        <div className="flex flex-col">
            <figure className="w-full px-3">
                <MapComponent addresses={addresses} />
            </figure>
            {itemsAtoms.map((item, i) => (
                <ListItem
                    key={name + i}
                    atom={item}
                    onClick={() => {
                        setSelectedItemIndex(i);
                        setShowDetailsView(true);
                    }}
                />
            ))}
        </div>
    );
};
