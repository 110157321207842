import { Outlet, RootRoute } from "@tanstack/react-router";
import React from "react";

export const rootRoute = new RootRoute({
    component: () => (
        <>
            <Outlet />
            {/* <TanStackRouterDevtools /> */}
        </>
    ),
});
