"use client";

import { AppSpec } from "@glide/appgpt-common";
import React, { useState } from "react";

import { App } from "@/components/App/index.jsx";
import { useOnce } from "@/components/hooks.js";

import { PexelsAttribution } from "./PexelsAttribution";

function finished(app: AppSpec) {
    window.parent.postMessage({ type: "finished", app }, "*");
}

export const Embed: React.FC = () => {
    const [prompt, setPrompt] = useState<string>();

    useOnce(() => {
        const params = new URLSearchParams(window.location.search);
        setPrompt(params.get("prompt") ?? undefined);

        window.addEventListener("message", event => {
            if (typeof event.data !== "object") return;
            if (typeof event.data.prompt !== "string") return;
            setPrompt(event.data.prompt);
        });
    });

    return (
        <div className="flex items-center justify-center h-full">
            <App prompt={prompt} finishedGenerating={finished} />
            <PexelsAttribution />
        </div>
    );
};
