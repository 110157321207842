import { type AppSpec, log } from "@glide/appgpt-common";

export function notifyStartedGenerating() {
    log("general", "appgpt started");
    window.parent.postMessage(
        {
            channel: "OnboardingAppGenerator",
            action: "appGeneratedStarted",
        },
        "*"
    );
}

export function close() {
    log("general", "appgpt sending close");
    window.parent.postMessage({ channel: "OnboardingAppGenerator", action: "close" }, "*");
}

export function continueWithApp(app: AppSpec) {
    log("general", "appgpt continuing with app in builder");
    window.parent.postMessage(
        {
            channel: "OnboardingAppGenerator",
            action: "appGeneratedSubmitted",
            app,
        },
        "*"
    );
}

export function skipDueToError(reason: string) {
    log("general", "appgpt skipping due to error", reason);
    window.parent.postMessage(
        {
            channel: "OnboardingAppGenerator",
            action: "appGeneratedSkipped",
            reason,
        },
        "*"
    );
}
