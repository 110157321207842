import { isStandalone } from "@glide/appgpt-common";
import * as Sentry from "@sentry/react";
import { ErrorComponent, Route, Router, RouterProvider } from "@tanstack/react-router";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Editor } from "./components/Editor.jsx";
import { Embed } from "./components/Embed.jsx";
import { ErrorComponentOnboarding } from "./components/ErrorComponentOnboarding.js";
import { generatorPageRoute } from "./components/GeneratorPage.jsx";
import { useOnce } from "./components/hooks.js";
import { rootRoute } from "./routes.js";

const routeTree = rootRoute.addChildren([
    generatorPageRoute,
    new Route({
        getParentRoute: () => rootRoute,
        path: "/editor",
        component: Editor,
    }),
    new Route({
        getParentRoute: () => rootRoute,
        path: "/embed",
        component: Embed,
    }),
]);

const router = new Router({
    routeTree,
    defaultPendingComponent: () => <div className={`p-2 text-2xl`}>{/* <Spinner /> */}</div>,
    defaultErrorComponent: ({ error }) => <GlobalError error={error} />,
    defaultPreload: "intent",
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}

function initSentry() {
    Sentry.init({
        dsn: "https://40debe9b5b5113c9cfe0b4a0665315b8@o4506445945372672.ingest.sentry.io/4506445952909312",

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1,

        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: false,

        replaysOnErrorSampleRate: 1,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // You can remove this option if you're not planning to use the Sentry Session Replay feature:
        integrations: [
            new Sentry.Replay({
                // Additional Replay configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
    });
}

export const App: React.FC = () => {
    useOnce(() => {
        try {
            initSentry();
        } catch (e) {
            console.error(e);
        }
    });

    return (
        <div>
            <ErrorBoundary FallbackComponent={GlobalError}>
                <RouterProvider router={router} defaultPreload="intent" />
            </ErrorBoundary>
        </div>
    );
};

const GlobalError: React.FC<{ error: any }> = ({ error }) => {
    React.useEffect(() => {
        if (import.meta.env.VITE_TEST === undefined) {
            Sentry.captureException(error);
        }
    }, [error]);

    if (!isStandalone) {
        return <ErrorComponentOnboarding />;
    }

    return <ErrorComponent error={error} />;
};
