import * as outline from "@heroicons/react/24/outline";
import * as solid from "@heroicons/react/24/solid";
import React, { createElement } from "react";

export function DynamicHeroIcon<T extends "solid" | "outline" = "solid">(props: {
    name: T extends "solid" ? keyof typeof solid : keyof typeof outline;
    type?: T;
    className?: string;
}) {
    const { name, type, className: overrides } = props;
    // latest classes override the defaults
    const className = ["h-6", "w-6", "text-current"].concat(overrides?.split(" ") || []).join(" ");
    const icon = { solid, outline }[type ?? "solid"][name];
    if (icon === undefined) {
        return <DynamicHeroIcon {...props} name="QuestionMarkCircleIcon" />;
    }
    // no need to use JSX syntax for "dynamically" acquired component
    return createElement(icon, { className });
}
