import { useEffect, useRef } from "react";

export function useOnce(fn: () => void) {
    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) return;
        mounted.current = true;
        fn();
    }, [fn]);
}
