import "mapbox-gl/dist/mapbox-gl.css";

import { camelCaseToTitle } from "@glide/appgpt-common";
import axios from "axios";
import { atom, useAtom, useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { Map as MapGL, Marker } from "react-map-gl";

import * as atoms from "@/atoms.js";

import { useShowDemoAlert } from "../DemoLimitAlert.jsx";
import { OtherDetailsView } from "./OtherDetailsView.jsx";

const MapComponent: React.FC<{ address: string }> = ({ address }) => {
    const [viewport, setViewport] = useState({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 11,
        style: { width: "100%", height: 240, borderRadius: 12 },
    });

    useEffect(() => {
        const geocodeAddress = async () => {
            try {
                const response = await axios.get(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                        address
                    )}.json?access_token=${import.meta.env.VITE_PUBLIC_MAPBOX_TOKEN}`
                );
                const { center } = response.data.features[0];
                setViewport({ ...viewport, longitude: center[0], latitude: center[1] });
            } catch (error) {
                console.error("Error during geocoding:", error);
            }
        };

        if (address) {
            geocodeAddress();
        }
    }, [address, viewport]);

    return (
        <MapGL
            {...viewport}
            mapboxAccessToken={import.meta.env.VITE_PUBLIC_MAPBOX_TOKEN}
            //   onViewportChange={nextViewport => setViewport(nextViewport)}
            mapStyle="mapbox://styles/mapbox/light-v11"
        >
            <Marker longitude={viewport.longitude} latitude={viewport.latitude} color="black" />
        </MapGL>
    );
};

const reservedKeys = new Set(["type", "image"]);

export const LocationDetailsView = () => {
    const [activeTab] = useAtom(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [selectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const item = useAtomValue(itemsAtoms[selectedItemIndex] ?? atom(undefined));
    const showDemoAlert = useShowDemoAlert();

    if (item === undefined) {
        return null;
    }
    const addressKey = Object.keys(item).find(key => key.toLowerCase().includes("address"));

    if (addressKey === undefined) {
        return <OtherDetailsView />;
    }

    const fields = Object.keys(item)
        .filter(key => !reservedKeys.has(key))
        .map(key => ({
            label: camelCaseToTitle(key),
            value: item[key],
        }));

    const title = item[activeTab.databaseColumns[0].name];
    const primaryButtonLabel = activeTab.detailsScreenCallToAction;

    return (
        <>
            <figure className="w-full px-3">
                <MapComponent address={item[addressKey]} />
            </figure>
            <h1 className="my-4 px-4 text-2xl font-semibold">{title}</h1>
            <section className="my-4 px-4">
                <dl className="divide-y divide-black/10 text-sm">
                    {fields.map((field, i) => (
                        <div key={field.toString() + i} className="grid grid-cols-3 py-2 gap-3">
                            <dt className="text-gray-500">{field.label}</dt>
                            <dd className="col-span-2">{field.value}</dd>
                        </div>
                    ))}
                </dl>
            </section>
            <section className="my-4 px-4">
                <button
                    className="p-3 w-full bg-aqua-600 rounded-lg font-semibold text-white text-sm transition hover:opacity-90 active:opacity-80"
                    onClick={showDemoAlert}
                >
                    {primaryButtonLabel}
                </button>
            </section>
        </>
    );
};
