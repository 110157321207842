import { camelCaseToTitle } from "@glide/appgpt-common";
import { atom, useAtom, useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

const reservedKeys = new Set(["type", "image", "gender"]);

export const PersonDetailsView = () => {
    const [activeTab] = useAtom(atoms.activeTab);
    const [itemsAtoms] = useAtom(atoms.activeTabItemsAtom);
    const [selectedItemIndex] = useAtom(atoms.selectedItemIndex);
    const item = useAtomValue(itemsAtoms[selectedItemIndex] ?? atom(undefined));

    const contactPerson = React.useCallback(() => {
        const mail = document.createElement("a");
        mail.href = `mailto:${item["email"] ?? "user@example.com"}`;
        mail.click();
    }, [item]);

    if (item === undefined) {
        return null;
    }

    const fields = Object.keys(item)
        .filter(key => !reservedKeys.has(key))
        .map(key => ({
            label: camelCaseToTitle(key),
            value: item[key],
        }));

    const title = item[activeTab.databaseColumns[0].name];
    const primaryButtonLabel = activeTab.detailsScreenCallToAction;

    return (
        <>
            <figure className="px-3">
                <img
                    src={item.image}
                    alt={title}
                    className="block object-cover w-32 h-32 mx-auto rounded-full border-4 border-black/20"
                />
            </figure>
            <h1 className="my-4 px-4 text-2xl font-semibold text-center">{title}</h1>
            <section className="my-4 px-4">
                <dl className="divide-y divide-black/10 text-sm">
                    {fields.map((field, i) => (
                        <div key={field.toString() + i} className="grid grid-cols-3 py-2 gap-3">
                            <dt className="text-gray-500">{field.label}</dt>
                            <dd className="col-span-2">{field.value}</dd>
                        </div>
                    ))}
                </dl>
            </section>
            <section className="my-4 px-4">
                <button
                    className="p-3 w-full bg-aqua-600 rounded-lg font-semibold text-white text-sm transition hover:opacity-90 active:opacity-80"
                    onClick={contactPerson}
                >
                    {primaryButtonLabel}
                </button>
            </section>
        </>
    );
};
