import { useAtomValue } from "jotai";
import React from "react";

import * as atoms from "@/atoms.js";

import { Spinner } from "./Image.jsx";

interface Props {
    isGenerating: boolean;
}

export const PhoneDashboard: React.FC<Props> = props => {
    const app = useAtomValue(atoms.app);
    const hasAppMetadata = app.name.length > 0;
    return (
        <div
            className="absolute inset-0 md:inset-px bg-white text-white md:rounded-[47px]"
            style={{
                background:
                    "radial-gradient(295.8% 106.92% at 49.87% 106.92%, #0E9DB0 0%, #07505A 33.33%, #021416 88.63%)",
            }}
        >
            {props.isGenerating && (
                <div className="absolute left-[24px] top-[60px] flex flex-col items-center gap-2">
                    <div className="rounded-[15px] overflow-hidden relative">
                        <img width={61} height={61} src="/generating-icon.svg" alt="" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            {hasAppMetadata ? (
                                <img
                                    className="w-9 h-9"
                                    src={`https://functions.prod.internal.glideapps.com/getEmoji/${app.icon}`}
                                    alt={""}
                                />
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    </div>
                    <div className="text-xs">{hasAppMetadata ? app.name : "Generating..."}</div>
                </div>
            )}
        </div>
    );
};
