import React from "react";

export function PexelsAttribution() {
    return (
        <span className="fixed bottom-4 left-0 right-0 text-center text-xs text-gray-600">
            Photos by{" "}
            <a href="https://www.pexels.com" target="_blank" className="text-aqua-400 hover:underline">
                Pexels
            </a>
        </span>
    );
}
