import { useAtomValue } from "jotai";
import React from "react";

import { useAnalytics } from "@/analytics/index.js";

import * as atoms from "../atoms.js";
import * as builder from "../builder.js";

export const useGoToBuilder = () => {
    const app = useAtomValue(atoms.app);
    const { trackEvent } = useAnalytics();
    const gotoBuilder = React.useCallback(async () => {
        trackEvent("continue_in_builder");
        builder.continueWithApp(app);
    }, [app, trackEvent]);

    return gotoBuilder;
};
